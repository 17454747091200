@import '../../../../@shared/mapbox/map.scss';

.readout-item {
  background-color: #eeeeee;
  max-height: 85px;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  font-size: 14px;

  margin-top: 10px;

  &.active {
    background-color: #e0e0e0;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
  }
}
