.asset-display {
  .card-block {
    padding: 0.7rem !important;

    .media-container {
      margin-right: 0.5rem;

      .media-barcode {
        display: none;
      }
    }
  }

  &:hover {
    cursor: pointer;

    .card-block {
      .media-barcode {
        display: flex !important;
        position: absolute;
      }
    }
  }
}
