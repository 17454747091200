.notification {
  position: fixed;
  top: 1em;
  left: 1em;
  z-index: 10000;

  .card {
    width: 18em;
    margin-bottom: 1em;
    box-shadow: 0.1em 0.2em 0.4em #555;

    animation: display_notification 0.5s ease;

    .close {
      position: absolute;
      top: 0.5em;
      right: 0.5em;
    }
  }
}

@keyframes display_notification {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
