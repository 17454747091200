@import '@shared/_overrides';
@import 'bootstrap/scss/bootstrap.scss';

.card-container {
  position: relative;
}

.image-card {
  overflow: hidden;
}

.active-area:not(.card-primary) {
  &:hover {
    background-color: $gray-100;
  }
}

.image-controls {
  position: absolute;
  z-index: 1000;
  display: inline-block;
  top: -0.5em;
  left: -0.5em;
  font-weight: 700;
}

.layer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;

  background-color: rgba(#000, 0.45);
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: $h1-font-size;

  &.danger {
    background-color: rgba($red, 0.65);
  }
}
