.notificationWrapper {
  display: flex;
  background-color: #f8f9fa;
  width: 400px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 2px;

  &:hover {
    border: 1px #efeff0 solid;
    background-color: #efeff0;
    cursor: pointer;
  }
}

.notificationInfo {
  padding: 4px;
}

.notificationCtrl {
  align-self: center;
  display: flex;
}

.groupTitle {
  font-size: 22px;
}

.iconWrapper {
  margin: auto;
  text-align: center;
  padding: 0 0 0 10px;
}

.iconLook {
  margin: 0.4em 0;

  border-radius: 50%;
  box-shadow: 0px 1px 2px #aaa;
  padding: 0.4em 0.4em;
  background-color: rgb(32, 172, 83);
  color: white;
  font-size: 2em;
}

.notificationTitle,
.notificationBody {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.notificationTitle {
  -webkit-line-clamp: 1; /* number of lines to show */
}

.notificationBody {
  -webkit-line-clamp: 2; /* number of lines to show */
}
