@import '../variables';

.cloud-navbar {
  height: 60px;

  .dropdown {
    display: flex;
    align-items: center;
  }

  .nav-link {
    height: 60px;
    display: flex;
    align-items: center;
  }
}
