$year-padding-top: 9px;
$content-padding-vertical: 10px;
$margin-bottom-even: 96px;

.p-r-15 {
  padding-right: 15px;
}

.p-t-15 {
  padding-top: 15px;
}

.no-p-l {
  padding-left: 0px !important;
}

.m-r-15 {
  margin-right: 15px;
}
