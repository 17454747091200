.table-scroll {
  width: 100%;
  margin-bottom: 0 !important;

  tbody {
    height: 379px;
    overflow-y: auto;
    width: 100%;
  }
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    display: block;
  }
  tbody {
    td {
      float: left;
    }
  }
  thead,
  tfoot {
    tr {
      th {
        float: left;
      }
    }
  }
}
