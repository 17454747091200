.expired {
  color: rgba(163, 0, 0, 0.8);
}

.task-row {
  font-size: 13px;
}

.task-title.btn.btn-link {
  font-size: 13px;
}

.task-border {
  border-left: 10px solid;
  border-radius: 0;
  &.task-completed {
    border-left-color: #2dc937;
  }
  &.task-started {
    border-left-color: #e7b416;
  }
  &.task-unstarted {
    border-left-color: #11579c;
  }
  &.task-canceled {
    border-left-color: #cc3232;
  }
  &.task-postponed {
    border-left-color: #ffa56e;
  }
  &.task-delayed {
    border-left-color: #db7b2b;
  }
}
