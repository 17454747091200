@import url('https://fonts.googleapis.com/css?family=Oxygen:400,700');

@import '@shared/_overrides';
@import 'bootstrap/scss/bootstrap.scss';
@import '@shared/variables';
@import 'react-datepicker/dist/react-datepicker';
@import '../node_modules/react-tagsinput/react-tagsinput.css';
@import '../node_modules/react-toggle/style.css';

body,
.btn,
.form-control,
[role='button'],
button,
input {
  font-family: $font-family-sans-serif;
}

html,
body {
  background-color: $gray-100;
  height: 100%;
}

.tiny {
  font-size: 0.5rem;
}

.app-body {
  background-color: $white;
  padding-top: $navbar-margin;
  padding-bottom: $navbar-margin;
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
}

.building-header {
  padding-top: $navbar-margin;
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

  .building-nav {
    margin-bottom: -1px;
  }
}

// .layout-container {
//   margin-top: $navbar-margin;
// }

.title-padded {
  margin-bottom: 1em;
}

.string-padded {
  margin-bottom: 0.5em;
}

.controls-row {
  margin-bottom: 0.5em;
}

.main-container,
.main-animation {
  animation: ShowPage 200ms ease-in;
}

@keyframes ShowPage {
  from {
    opacity: 0;
    z-index: 1;
    transform: translateY(10%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.organization-card,
.provider-card {
  width: 4em;
  height: 4em;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-lg;
}

.card-wrapper {
  display: inline-block;
}

.profile-card {
  overflow: hidden;
}

.profile-image {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  img {
    width: 100%;
  }
}

.file-label {
  display: flex;
  width: 100%;
  height: 10rem;
  align-items: center;
  justify-content: center;
  background-color: $gray-100;
  border-radius: $border-radius-lg;

  &:hover {
    background-color: $gray-200;
  }

  div {
    text-align: center;
  }
}

.link-unstyled,
.link-unstyled:link,
.link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}

.link-card {
  width: 30%;
  @media (max-width: 767px) {
    width: 45%;
  }
}

.link-unstyled,
.link-unstyled:link,
.link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}

.link-card {
  width: 30%;
  @media (max-width: 767px) {
    width: 45%;
  }

  min-height: 10rem;

  background-color: #fefefe;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);
  color: $gray-600 !important;

  @extend .p-2;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .flex-column;
  @extend .flex-wrap;
  @extend .text-center;

  &:hover {
    @extend .bg-light;
  }
}

.post-card:hover {
  background-color: $gray-100;
}

.bordered-gray {
  border: $gray-200 1px solid;
}

.button-collapse,
.text-overflow {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.markdown-area {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend .mb-3;
  }
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.building-nav .nav-link {
  color: $gray-600 !important;

  &.active {
    color: $black !important;
    border-top: $blue 3px solid !important;
    margin-bottom: -3px;
    padding-top: 6px;
  }
}

.building-nav .nav-item.dropdown.show .nav-link.active:not(.link-active) {
  color: $blue !important;
  background-color: transparent;
  border-color: transparent !important;
  border-width: 1px !important;
  padding-top: 8px;
}

.desk-movements {
  max-height: 20rem;
  overflow-y: scroll;
}

.number-circle {
  @extend .h3;
  @extend .p-0;
  @extend .m-0;

  width: 1.8em;
  height: 1.8em;

  line-height: 1.5em;
  text-align: center;

  background-color: $white;
  color: $gray-700;

  box-sizing: border-box;
  border-radius: 50%;
  border: $teal 0.15em solid;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__input-container {
  display: block;
}

.react-datepicker {
  font-family: $font-family-sans-serif;
  font-size: 0.85rem;
  display: flex;
}

.react-datepicker__header {
  background-color: #f7f7f9;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-right: 0px;
  padding-left: 0px;
  font-size: 10.5px;
}

.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker__close-icon {
  display: inline;
  // opacity: 0.5;

  &::after {
    background-color: transparent;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 0.3;
    color: hsl(0, 0%, 80%);
    text-shadow: 0 1px 0 #fff;
    font-family: 'Oxygen', sans-serif;
  }

  &:hover {
    &::after {
      color: hsl(0, 0%, 60%) !important;
    }
  }
}

.list-group-menu {
  @extend .mb-2;
  overflow-y: scroll;
}

.list-group-menu {
  .list-group-item {
    border-bottom: $list-group-border-width solid $list-group-border-color !important;
    border-radius: 0 !important;
  }

  .list-group-item:last-child {
    border-bottom: 0 !important;
  }
}

.modal-backdrop.fade {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.inline-search-padding {
  padding: 0.45rem 0.5rem;
}

.chip {
  display: inline-flex;
  padding: 7px 12px;
  border-radius: 24px;
  align-items: center;
  cursor: default;
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  line-height: 18px;
  margin-right: 4px;
  margin-bottom: 4px;

  a {
    width: 18px;
    height: 18px;
    background-color: rgba(0, 0, 0, 0.87);
    opacity: 0.4;
    margin-right: -4px;
    margin-left: 6px;
    cursor: pointer;
    border-radius: 50%;
    line-height: 15px;

    i {
      color: #e0e0e0;
      font-size: 10px;
      font-weight: normal;
      font-style: normal;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      padding-left: 4.5px;
      text-rendering: optimizeLegibility;
    }
  }
}

.react-tagsinput-input {
  min-width: 100px !important;
}

.form-control.tags {
  padding-bottom: 0.3rem;
}

.asset-cloud-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.assembly-toggle-text {
  vertical-align: top;
}

@import '@shared/table/Smartgrid.scss';
@import '@shared/editable/editable.scss';

.p-2 {
  padding: 2px;
}
.no-pad {
  padding: 0px;
}

.no-pad-l {
  padding-left: 0px !important;
}

.no-pad-r {
  padding-right: 0px !important;
}

.no-m-b {
  margin-bottom: 0 !important;
}

kbd {
  &.danger {
    background-color: theme-color('danger');
  }
}

.chart-title-container {
  padding-top: 1rem;
  padding-bottom: 1rem !important;

  h4 {
    font-size: 1.25rem;
    margin-bottom: 5px;
  }
}

.fa {
  font-family: FontAwesome !important;
}

.content-actions {
  .btn {
    margin-left: 0.25rem;
    cursor: pointer;
  }
  .btn-group {
    margin-left: 0.25rem;
  }
}

.btn.map-custom-button {
  height: 40px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  background-color: rgb(255, 255, 255);
  padding: 0px 17px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  min-width: 66px;
  border-radius: 2px;
  border: 0;
}

.ml-20 {
  margin-left: 20px;
}

.nav-tabs .nav-link {
  cursor: pointer;
}

.btn-secondary {
  border-color: #ccc;

  &.disabled {
    border-color: #ccc;
  }
}

// NEW OVERRIDES

.btn-warning,
.badge-warning {
  color: white;

  &:hover {
    color: white;
  }
}

code {
  padding: 0.2rem 0.4rem;
  background-color: #f7f7f9;
  border-radius: 0.25rem;
}

.status-border {
  border-left: 5px solid;
  border-radius: 0;

  &.status-border-active {
    border-left-color: $green;
  }

  &.status-border-inactive,
  &.status-border-suspended {
    border-left-color: $orange;
  }

  &.mro-requires_repair,
  &.mro-requires_evaluation,
  &.mro-requires_maintenances,
  &.mro-requires_preservation,
  &.mro-requires_certification,
  &.mro-requires_assurance,
  &.mro-requires_discard,
  &.mro-pending {
    border-left-color: #cc3232;
  }

  &.mro-on_transit,
  &.mro-rejected {
    border-left-color: #ffa56e;
  }

  &.mro-in_supplier {
    border-left-color: #db7b2b;
  }

  &.mro-on_repair,
  &.mro-on_evaluation,
  &.mro-evaluation_reported,
  &.mro-on_maintenance,
  &.mro-on_preservation,
  &.mro-on_certification,
  &.mro-on_assurance,
  &.mro-on_discard {
    border-left-color: #e7b416;
  }

  &.mro-return {
    border-left-color: #99c140;
  }

  &.mro-completed {
    border-left-color: #2dc937;
  }
}

.event-border {
  border-left: 10px solid;
  border-radius: 0;
  &.event-created {
    border-left-color: #2dc937;
  }
  &.event-updated {
    border-left-color: #e7b416;
  }
  &.event-deleted {
    border-left-color: #cc3232;
  }
  &.event-transferred {
    border-left-color: #e7b416;
  }
  &.event-deactivated {
    border-left-color: #cc3232;
  }
  &.event-reseted {
    border-left-color: #e7b416;
  }
  &.event-borrowed {
    border-left-color: #e7b416;
  }
  &.event-starred {
    border-left-color: #2dc937;
  }
  &.event-completed {
    border-left-color: #2dc937;
  }
  &.event-stockout {
    border-left-color: #e7b416;
  }
  &.event-bad {
    border-left-color: #cc3232;
  }
}

.pb-1 {
  padding-bottom: 1px;
}

.resource-kit-inputs {
  .form-group {
    margin-bottom: 0;
  }
}

.no-form-group-mb .form-group {
  margin-bottom: 0;
}

:root {
  --tw-space-y-reverse: 0;
}

.space-y-1 > * + * {
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-y-2 > * + * {
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > * + * {
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
