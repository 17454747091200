.attachment-display {
  .attachment-download {
    align-items: center;
    justify-content: center;
  }

  .revel {
    display: none;
  }

  .unrevel {
    display: flex;
  }

  .card-block {
    padding: 0.5rem 0.7rem;
    display: flex;

    .attachment-type {
      margin-right: 10px;
    }

    .attachment-info {
      flex: 1;
      font-size: 12px;
    }

    .attachment-download {
      font-size: 1em;
    }
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;

    .revel {
      display: flex;
    }

    .unrevel {
      display: none;
    }
  }
}
