.editable-container select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0px;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
  background-position: 99% 50%;
  background-repeat: no-repeat;
  background-size: 16px;
}
