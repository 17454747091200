@import '../overrides';

.Circle {
  display: inline-block;

  border: $teal 0.25rem solid;
  border-radius: 50%;

  width: 4rem;
  height: 4rem;

  font-size: 2em;
  font-weight: 700;

  line-height: 2em;
  text-align: center;
}
