.smartgrid {
  tbody td {
    .editable {
      position: relative;

      .editable-toggle {
        position: absolute;
        display: none;
        right: 0;
      }
    }

    &:hover {
      background-color: #d9edf7;
      border-color: #bcdff1;
      color: #31708f;

      > .editable > .editable-toggle {
        display: inline;
      }
    }

    &.has-error {
      background-color: #fcf8e3;
      border-color: #faf2cc;
      color: #8a6d3b;
    }
  }

  &.is-editing {
    th {
      pointer-events: none;
    }

    td {
      > .editable {
        pointer-events: none;
        > .editable-toggle {
          display: none !important;
        }
      }
    }
  }
}
