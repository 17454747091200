.readouts-map {
  .sidebar {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px 0 0 27px;
    background-color: #404040;
    color: #ffffff;
    z-index: 700 !important;
    padding: 6px 12px;
    border-radius: 9999px !important;
    background-color: rgba(0, 0, 0, 0.75) !important;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
  }

  .map-container {
    height: 100%;
    border-radius: 4px;
  }
}
