$links-padding: 0.75em;

@import '../variables';

.footer {
  padding-top: 0.5em;
  padding-bottom: 1em;

  border-top: $brand-color 0.1em solid;
}

.links {
  display: block;

  li {
    display: inline-block;
    list-style: none;

    padding-left: $links-padding;
    padding-right: $links-padding;

    &:last {
      padding-right: 0;
    }
  }
}

@media (max-width: 768px) {
  .links {
    text-align: left;
    padding: 0;
    padding-top: 1em;

    li {
      display: block;
      padding: 0;
    }
  }
}

@media (min-width: 768px) {
  .links {
    text-align: right;
  }
}
