.table-scroll-users {
  tbody {
    height: 345px !important;
    td {
      height: 43px !important;
    }

    tr > td:not(:first-child) {
      line-height: 32px;
    }
  }
}
