.subscriptionToggleContainer {
  display: flex;
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 4px;
}

.subscriptionInfo {
  flex: 1;
}

.subscriptionCtrl {
  align-self: center;
  display: flex;
}

.groupTitle {
  font-size: 22px;
}
