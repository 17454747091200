.edit-subtask-btn {
  margin-left: auto;
}

.delete-subtask-btn {
  margin-left: 5px;
}

.subtask-view {
  width: 100%;
  display: flex;
}

.subtask-edit.form-group {
  width: 100%;
  margin-bottom: 0;
}

.subtask-list-item {
  min-height: 64px;
}
