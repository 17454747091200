@import '@shared/_overrides';
@import 'bootstrap/scss/bootstrap.scss';

.fu-dropzone {
  .fu-dropzone-active {
    @extend .alert-success;
    @extend .p-3;
  }

  .fu-dropzone-button {
    @extend .alert-info;
    @extend .p-3;

    &:hover {
      background-color: lighten(theme-color('info'), 10);
    }
  }

  .fu-dropzone-disabled {
    cursor: not-allowed !important;
  }
}
