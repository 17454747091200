.task {
  padding: 10px;
  background-color: rgb(242, 242, 242) !important;
  flex-direction: row !important;

  > .ctrl {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    color: #10579c;
  }

  > .content {
    flex: 1;
    padding: 0 10px;

    .content-footer {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }

    .content-title {
      color: rgba(0, 0, 0, 0.9);
    }
  }

  &.completed {
    .content-title {
      text-decoration: line-through;
    }
  }

  &.expired:not(.completed) {
    .content-footer {
      color: rgba(163, 0, 0, 0.8);
    }
  }

  &.selected {
    background-color: #e7f0fb !important;
  }
}
