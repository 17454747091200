@mixin bg-strike($color) {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  padding: 5px 0;

  > span {
    position: relative;
    display: inline-block;
    font-size: 14px;
    color: $color;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 1px;
      background: $color;
    }

    &:before {
      right: 100%;
      margin-right: 15px;
    }

    &:after {
      left: 100%;
      margin-left: 15px;
    }
  }
}

.task-sidebar.card {
  width: 400px;
  background-color: rgb(242, 242, 242);
  padding: 10px;

  hr {
    margin: 0.5em 0;
  }

  .strike {
    @include bg-strike(#ccc);
  }

  .ctrl {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
  }

  .content {
    flex: 1;
  }

  .task-sidebar-header {
    display: flex;
    flex-direction: column;

    .task-controllers {
      display: flex;

      .ctrl {
        align-items: stretch;
        color: #10579c;
        margin-top: 6px;
      }

      .content {
        display: flex;
        padding: 0 10px;
        .content-title {
          font-size: 1.5em;
        }
      }
    }
  }

  .subtasks {
    .task-controllers {
      margin-left: 10px;

      .content-title {
        font-size: 1rem !important;
      }

      .ctrl {
        font-size: 1.2em;
        margin-top: 3px;
      }
    }
  }

  section {
    .collapsable {
      &.false {
        display: none;
      }
    }

    &.description {
      flex: 1;
    }

    &.sidebar-footer {
      display: flex;
      color: rgba(0, 0, 0, 0.5);

      .ctrl {
        font-size: 1.2em;
        min-width: 24px;

        &.danger {
          color: #a30000;
        }
      }
      .content {
        span {
          display: block;
          text-align: center;
          font-size: 1em;
        }
      }
    }

    &.attachments {
      .attachment-display {
        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }
    }
  }

  .pointer {
    &:hover {
      cursor: pointer;
    }
  }
}
