.repair-form-division {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .outer-line {
    width: 45%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .repair-division-content {
    border-radius: 50%;
    z-index: 3;
    text-align: center;

    margin-left: 1rem;
    margin-right: 1rem;

    .fa {
      z-index: 1;
    }

    &:before {
      content: '';
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #fff;
      border: 2px solid #727cb6;
      box-shadow: 0 0 0 4px #a5afe4;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.btn {
  &.info-button {
    border-radius: 50%;
    padding: 0;
    margin-left: 0.5rem;
    cursor: pointer;
  }
}

.tour-assets-list {
  .asset-list-group {
    flex-flow: row nowrap;
    display: flex;

    .asset-card-content {
      flex-grow: 1;
    }
  }
}
