.task-list {
  flex: 1;
  min-height: 760px;

  .task-item:hover {
    cursor: pointer;
  }

  .task-item:not(:last-child) {
    margin-bottom: 10px;
  }
}
