@import '../@shared/mapbox/map.scss';

.gm-style-iw {
  > .gm-style-iw-d {
    overflow: auto !important;
  }

  padding: 0 !important;
  border-radius: 10px !important;
  font-size: 15px !important;
  max-width: 430px !important;

  .group-item {
    width: 420px;
    background-color: #fff !important;
    height: 85px !important;
    border-radius: 10px !important;
    margin-bottom: 0 !important;

    kbd {
      line-height: 14.7px !important;
    }
  }
}

.ReactVirtualized__List,
.ReactVirtualized__Grid__innerScrollContainer {
  &:focus {
    outline: -webkit-focus-ring-color auto 0px;
  }
}

.group-item {
  background-color: #eeeeee;
  height: 85px;
  border-radius: 4px;
  padding: 10px;
  display: flex;

  &.active {
    background-color: #e0e0e0;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
  }

  .asset-img {
    width: 65px;
    height: 65px;
    border-radius: 4px;
  }

  .asset-name {
    font-weight: bold;
  }

  kbd,
  code {
    padding: 0.2rem 0.2rem;
    font-size: 70%;
    border-radius: 0.2rem;
  }

  .readout-content {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    flex-grow: 1;
  }

  .overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
